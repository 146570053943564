<template>
  <div class="orderinfo">
     <div class="title">
        <img class="blocks" src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>服务单详情</span>
        <img  @click="openService()" class="customer" src="../../../../src/assets/image/customer.png" alt="">
     </div>
     <div class="status">
        <div class="name">
          已取消
        </div>
        <van-steps active="1" active-color="#E0BFB1">
            <van-step>申请</van-step>
            <van-step>取消</van-step>
        </van-steps>
     </div>
      <div class="site site2">
          <div class="site_text">
           <div class="text1">服务单已取消</div>
           <van-icon name="arrow" />
          </div>
          <div class="time">该服务单已取消，如有需要可在售后申请页重</div>
      </div>

      <!-- 商品 -->
      <div class="goods" v-for="(item, index) in orderInfo.sub" :key="index">
         <img :src="item.topPic" alt="">
         <div class="goodinfo">
            <div class="name">{{item.name}}</div>
            <div class="tags"><span>{{item.skuAttr}}</span><span>×{{item.number}}</span></div>
            <div class="price">
              ￥{{item.skuPrice}}
            </div>
         </div>
      </div>
      <!-- 订单 -->
      <div class="order_nav">
         <div class="order_flex">
            <div class="left">服务单号</div>
            <div class="right">{{orderInfo.orderId}}</div>
         </div>
         <div class="order_flex">
            <div class="left">申请时间</div>
            <div class="right">{{orderInfo.createTime}}</div>
         </div>
         <div class="order_flex">
            <div class="left">服务类型</div>
            <div class="right">{{orderInfo.refundType == 1?'退货':'换货'}}</div>
         </div>
      </div>
    <bottomList></bottomList>
  </div>
</template>

<script>
import { getOrderInfo } from '@/api/order'

export default {
  name: "serviceCancel",

  data() {
    return {
      active: 1,
      orderInfo: {},
      NowTime: '', // 当前时间
      show: false,
      columns: [],
      showPicker: false,
      editShow: false,
      gsname: '请选择寄回快递公司',
      huiOrderId: '请输入寄回商品的快递单号',
      huiOrderIdNum: '',
      expressCompanyId: '',
    };
  },

  mounted() {
    if(this.$route.params.orderId) {
        localStorage.setItem('orderId', this.$route.params.orderId)
    } else {
        this.$route.params.orderId = localStorage.getItem('orderId')
    }
    this.getInfo()
  },

  methods: {
    // 跳转客服
    openService() {
        window.open('https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=blD4dOkH24')
    },
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    onConfirm(value) {
    //   this.value = value;
      this.gsname = value.name
      this.expressCompanyId = value.id
      this.showPicker = false;
    },
    // 获取订单信息
    getInfo() {
      var that = this
      getOrderInfo(that.$route.params.orderId).then(res => {
        that.orderInfo = res.data
        if (that.orderInfo.refund.expressNo) {
            that.huiOrderId = that.orderInfo.refund.expressNo
            that.huiOrderIdNum = that.orderInfo.refund.expressNo
            that.gsname = that.orderInfo.refund.expressCompanyName
            that.expressCompanyId = that.orderInfo.refund.expressCompanyId
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.orderinfo{
   background: #F6F7FA;
   padding-bottom: 40rem;
   width: 100vw;
   height: 100vh;
}
.title{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    .blocks{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
    .customer{
        position: absolute;
        right: 14rem;
        width: 23rem;
        height: 21rem;
    }
}
.status{
  width: calc(100vw - 32rem);
  height: 102rem;
  padding: 0rem 16rem;
  background: #000000;
  background: url('../../../../src/assets/image/bj.png');
  background-size: 100% 100%;
  .name{
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 800;
    font-size: 17rem;
    color: #FFFFFF;
    padding-top: 22rem;
  }
  .tig{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 13rem;
    color: #F6F7FA;
    margin-top: 2rem;
  }
}

.site{
    width: calc(100vw - 30rem);
    // height: 44rem;
    background: #FFFFFF;
    padding: 15rem;
    .site_text{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text1{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15rem;
            color: #333333;
            img{
                width: 25rem;
                height: 26rem;
                vertical-align: middle;
            }
        }
    }
    .time{
        font-family: PingFangSC, PingFang SC;
        font-weight: 300;
        font-size: 14rem;
        color: #000000;
        margin-top: 4rem;
    }
}
.site2{
  margin-top: 10rem;
  .imgdinwei{
    width: 23rem !important;
  }
}
.goods{
  margin-top: 10rem;
  display: flex;
  padding: 15rem;
  background: #fff;
  img{
    width: 98rem;
    height: 98rem;
  }
  .goodinfo{
    margin-left: 8rem;
    width: calc(100% - 116rem);
    .name{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #000000;
    }
    .tags{
      display: flex;
      margin-top: 5rem;
      justify-content: space-between;
      span{
          font-family: PingFangSC, PingFang SC;
          font-weight: 300;
          font-size: 13rem;
          color: #939297;
      }
    }
    .price{
      font-family: DINPro, DINPro;
      font-weight: 500;
      font-size: 14rem;
      color: #000000;
      margin-top: 32rem;
    }
  }
}
.order_nav{
  margin-top: 10rem;
  padding: 18rem 14rem;
  background: #fff;
  .order_flex{
    display: flex;
    justify-content: space-between;
    padding-bottom: 18rem;
    .left{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #666C73;
    }
    .right{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #000000;
    }
  }
  .order_all{
    padding-top: 14rem;
    text-align: right;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14rem;
    color: #000000;
    border-top: 1rem solid #F6F7FA;
  }
}
.last_but{
  width: 100vw;
  height: 60rem;
  line-height: 60rem;
  background: #FFFFFF;
  border: 1rem solid #F0F0F0;
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  text-align: right;
  span{
    display: inline-block;
    line-height: 44rem;
    width: 107rem;
    height: 44rem;
    text-align: center;
    border: 1rem solid #C5C8D3;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15rem;
    color: #000000;
    margin-right: 9rem;
    margin-top: 7rem;
  }
  .qrsh{
    background: #E2BFB0;
    border: none !important;
  }
}
/deep/.van-steps,/deep/ .van-step--horizontal .van-step__circle-container{
    background-color: transparent;
}
.next_but{
    background: #E0BFB1;
    border: none;
}
.pop_box{
    width: 247rem;
    height: 150rem;
    background: #FFFFFF;
    padding: 24rem;
    .pop_title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18rem;
        color: #3C3C3C;
        text-align: center;
    }
    .pop_input{
        margin-top: 12rem;
        input{
            height: 46rem;
            background: #F6F7FA;
            border: none;
            width: calc(100% - 10rem);
            padding-left: 10rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15rem;
            color: #C5C8D3;
        }
    }
    .pop_but{
        margin-top: 27rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
            display: inline-block;
            width: 116rem;
            height: 40rem;
            border: 1rem solid #ACB5B9;
            text-align: center;
            line-height: 40rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15rem;
            color: #000000;
        }
        .qued{
            border: none;
            background: #E0BFB1;
        }
    }
}
</style>
